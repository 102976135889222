import React, { ReactElement } from 'react'

import '../i18n/i18n'

import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import SVGSymbols from '~components/homepage/svg/icons/svg'
import CookiesPopup from '~components/homepage/sections/cookies-popup'

import {
  Container,
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'
import Header from '~components/homepage/sections/header'
import FooterSection from '~components/homepage/sections/footer-section'
import { Helmet } from '~components/helmet'

const StyledContentContainer = styled(ContentContainer)`
  padding-top: 120px;
  padding-left: 100px;
  padding-right: 100px;
`

const ArticlesPage = ({ t, i18n }): ReactElement => {
  const switchLang = (lng: string): void => i18n.changeLanguage(lng)

  return (
    <SectionWrapper bgColor="#F7F5F1">
      <Helmet />
      <Container>
        <GlobalStyle />
        <LanguageContext.Provider value={{ t, lang: i18n.language }}>
          <CookiesPopup />
          <Header onSwitchLang={switchLang} />
          <SVGSymbols />
          <StyledContentContainer>
            <br />
            <br />
            <br />
            <br />
            <h1 style={{ textAlign: 'center' }}>REGULAMIN</h1>
            <br />
            <br />
            <br />
            <br />
            <div>
              <h5>
                REGULAMIN WARSZTAT&Oacute;W &quot;ZR&Oacute;B N&Oacute;Ż&quot;
              </h5>
              <br />
              <h4>WSTĘP</h4>
              <ol>
                <li>
                  Regulamin określa og&oacute;lne zasady korzystania ze
                  strony&nbsp;
                  <a href="http://www.klosy.pl/" rel="nofollow">
                    http://www.klosy.pl
                  </a>
                  , oraz organizacji zapis&oacute;w i przeprowadzania
                  warsztat&oacute;w &bdquo;Zr&oacute;b N&oacute;ż&rdquo;.&nbsp;
                </li>
                <li>
                  Organizatorem Warsztat&oacute;w jest KŁOSY Sp&oacute;łka z
                  ograniczoną odpowiedzialnością, zarejestrowana pod adresem
                  Rzeczna 22, 05-504 w Złotokłosie. O Numerze NIP 123 136 57 88,
                  KRS 0000691246. &nbsp;Pracownia znajduje się pod adresem Tamka
                  40, 00-355 w Warszawie. Sp&oacute;łka zw. jest dalej
                  Organizatorem.
                </li>
              </ol>
              <br />
              <h4>ZASADY ROZLICZEŃ I PRZEPROWADZANIA ZAPIS&Oacute;W</h4>
              <ol>
                <li>
                  Potwierdzeniem rezerwacji miejsca na warsztatach jest
                  wypełnienie formularza na stronie&nbsp;
                  <a href="http://www.klosy.pl/workshop">
                    www.klosy.pl/workshop
                  </a>{' '}
                  wraz z przelaniem zadatku na konto KŁOSY Sp. z o. o. &ndash;
                  informacje dot. Płatności zostaną przesłane za pośrednictwem
                  email, w odpowiedzi na przesłane zgłoszenie.
                </li>
                <li>
                  Wysokości zaliczek za poszczeg&oacute;lne rodzaje zajęć
                  wyszczeg&oacute;lnione są w opisach na stronie z
                  zapisami.&nbsp;
                </li>
                <li>
                  Reszta kwoty zobowiązania za warsztaty powinna zostać przelana
                  na konto najp&oacute;źniej na 2 tygodnie przed planowanym
                  dniem rozpoczęcia zajęć.
                </li>
                <li>
                  Na prośbę Uczestnika dopuszcza się płatność za zajęcia z
                  g&oacute;ry &ndash; jednym przelewem.
                </li>
                <li>
                  Ew. rezygnacja z zajęć (ze zwrotem przelanego zadatku), lub
                  zmiana terminu zajęć na p&oacute;źniejszy(z zachowaniem
                  przelanego zadatku), &nbsp;może nastąpić najp&oacute;źniej na
                  2 tygodnie przed planowanym dniem rozpoczęcia zajęć.&nbsp;
                </li>
                <li>
                  Brak kontaktu ze strony uczestnika, brak uzupełniającego
                  przelewu lub niestawienie się na wykupiony kurs jest
                  jednoznaczne z utraceniem wykupionego miejsca i przesłanej do
                  czasu zajęć kwoty.
                </li>
                <li>
                  Organizator warsztat&oacute;w zastrzega sobie prawo do
                  indywidualnego rozpatrywania sytuacji nietypowych.
                </li>
                <li>
                  W przypadku nie zebrania się minimalnej ilości
                  uczestnik&oacute;w na dany termin, Organizator zastrzega sobie
                  prawo do przeniesienia zajęć na inny termin, dając Uczestnikom
                  możliwość wyboru dowolnej dostępnej grupy.
                </li>
                <li>
                  Odwołanie grupy przez Organizatora może nastąpić
                  najp&oacute;źniej na 7 dni przed rozpoczęciem danego kursu.
                </li>
              </ol>
              <br />
              <h4>UCZESTNIK i ZASADY BEZPIECZEŃSTWA</h4>
              <ol>
                <li>
                  Uczestnicy zajęć biorą w nich udział na własne ryzyko i
                  odpowiedzialność, ze świadomością ew. konsekwencji
                  zdrowotnych, wynikających z pracy z użyciem narzędzi i maszyn.
                </li>
                <li>
                  Uczestnicy, w trakcie kursu, zostają przeszkoleni z obsługi
                  sprzętu używanego na warsztatach i poinstruowani co do zasad
                  BHP i ppoż. obowiązujących w pracowni.
                </li>
                <li>
                  Uczestnicy, na czas trwania zajęć, są wyposażeni przez
                  Organizatora w zestaw urządzeń ochronnych ( ochrona słuchu,
                  ochrona oczu i maski przeciwpyłowe )
                </li>
                <li>
                  Organizator dopuszcza możliwość uczestniczenia w zajęciach
                  os&oacute;b niepełnoletnich, przy zastrzeżeniu dodatkowej
                  obecności na zajęciach opiekuna prawnego.
                </li>
                <li>
                  Organizator zastrzega prawo wykluczenia z zajęć Uczestnika,
                  wobec kt&oacute;rego zachodzi podejrzenie stawienia się na
                  zajęciach w stanie nietrzeźwym.
                </li>
              </ol>
              <br />
              <h4>KARTY PODARUNKOWE</h4>
              <ol>
                <li>
                  Organizator umożliwia wykupienie karty podarunkowej, na
                  określony typ warsztat&oacute;w.&nbsp;
                </li>
                <li>
                  Przy zakupie karty podarunkowej rozliczana jest pełna kwota
                  należności za wybrany kurs.&nbsp;
                </li>
                <li>Karty podarunkowe nie podlegają zwrotowi.</li>
                <li>
                  Osoba obdarowana, po skontaktowaniu się z Organizatorem
                  zapisuje się na odpowiadający jej termin kursu.
                </li>
                <li>Wybrany termin kursu jest wiążący dla obu stron.</li>
                <li>
                  Termin zajęć Uczestnik może zmienić bez konsekwencji,
                  najp&oacute;źniej na dwa tygodnie przed dniem ich rozpoczęcia.
                </li>
                <li>
                  W przypadku niestawienia się w um&oacute;wionym terminie na
                  zajęcia lub w przypadku braku kontaktu z Uczestnikiem karta
                  przepada.
                </li>
                <li>
                  Karty podarunkowe są ważne 12 miesięcy od dnia ich wydania.
                </li>
              </ol>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <div style={{ textAlign: 'center' }}>
                <p>KONTAKT do Organizatora</p>
                <p>Piotr Jędras / KŁOSY Sp. z o. o.</p>
                <p>warsztaty@klosy.pl</p>
                <p>+48 506 075 243</p>
                <p>ul. Tamka 40</p>
                <p>00-355 Warszawa</p>
              </div>
            </div>
          </StyledContentContainer>
          <FooterSection />
        </LanguageContext.Provider>
      </Container>
    </SectionWrapper>
  )
}

export default withTranslation('translation')(ArticlesPage)
